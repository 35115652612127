module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nishal Kulkarni","short_name":"Nishal Kulkarni","start_url":"/","background_color":"#ffffff","theme_color":"#0453ad","display":"standalone","icon":"src/images/icon.png","theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c27e7bca4d3f0b6278d1926f1e84837a"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1024,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-styled-components-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"light":{"primaryBgColor":"#ffffff","secondaryBgColor":"#f5f5f5","pageBorderColor":"#f0f0f0","underlineLightColor":"#ebebeb","grayBorderColor":"#cccccc","blueColor":"#0453ad","altBlueColor":"#04448e","redColor":"#b01722","altRedColor":"#9c1722","yellowColor":"#f0d461","altYellowColor":"#ecc732","lightYellowColor":"#fefdf6","altLightYellowColor":"#fbf5da","textWeight1":"#111111","textWeight2":"#333333","textWeight3":"#585858","textWeight4":"#404040","textWeight5":"#383838"},"dark":{"primaryBgColor":"#0a0a0a","secondaryBgColor":"#000000","pageBorderColor":"#0f0f0f","underlineLightColor":"#141414","grayBorderColor":"#a6a6a6","blueColor":"#00bffd","altBlueColor":"#00cffd","redColor":"#da424c","altRedColor":"#9c1722","yellowColor":"#f0d461","altYellowColor":"#ecc732","lightYellowColor":"#fefdf6","altLightYellowColor":"#fbf5da","textWeight1":"#ededed","textWeight2":"#cccccc","textWeight3":"#c7c7c7","textWeight4":"#bfbfbf","textWeight5":"#b1b1b1"}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
