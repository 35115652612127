exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-blog-post-template-js-content-file-path-posts-2020-2020-02-05-introduction-to-shell-scripting-index-mdx": () => import("./../../../src/templates/blogPostTemplate.js?__contentFilePath=/opt/buildhome/repo/posts/2020/2020-02-05-introduction-to-shell-scripting/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-js-content-file-path-posts-2020-2020-02-05-introduction-to-shell-scripting-index-mdx" */),
  "component---src-templates-blog-post-template-js-content-file-path-posts-2021-2021-02-05-vanilla-cake-watercolor-painting-and-new-beginnings-index-mdx": () => import("./../../../src/templates/blogPostTemplate.js?__contentFilePath=/opt/buildhome/repo/posts/2021/2021-02-05-vanilla-cake-watercolor-painting-and-new-beginnings/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-js-content-file-path-posts-2021-2021-02-05-vanilla-cake-watercolor-painting-and-new-beginnings-index-mdx" */),
  "component---src-templates-blog-post-template-js-content-file-path-posts-2021-2021-02-17-restoring-old-multibit-wallets-and-a-disastorous-painting-index-mdx": () => import("./../../../src/templates/blogPostTemplate.js?__contentFilePath=/opt/buildhome/repo/posts/2021/2021-02-17-restoring-old-multibit-wallets-and-a-disastorous-painting/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-js-content-file-path-posts-2021-2021-02-17-restoring-old-multibit-wallets-and-a-disastorous-painting-index-mdx" */),
  "component---src-templates-blog-post-template-js-content-file-path-posts-2021-2021-06-04-turning-over-a-new-leaf-with-gnome-index-mdx": () => import("./../../../src/templates/blogPostTemplate.js?__contentFilePath=/opt/buildhome/repo/posts/2021/2021-06-04-turning-over-a-new-leaf-with-gnome/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-js-content-file-path-posts-2021-2021-06-04-turning-over-a-new-leaf-with-gnome-index-mdx" */),
  "component---src-templates-blog-post-template-js-content-file-path-posts-2021-2021-06-22-tracking-windows-and-monitoring-files-index-mdx": () => import("./../../../src/templates/blogPostTemplate.js?__contentFilePath=/opt/buildhome/repo/posts/2021/2021-06-22-tracking-windows-and-monitoring-files/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-js-content-file-path-posts-2021-2021-06-22-tracking-windows-and-monitoring-files-index-mdx" */),
  "component---src-templates-blog-post-template-js-content-file-path-posts-2021-2021-08-10-merge-requests-and-guadec-index-mdx": () => import("./../../../src/templates/blogPostTemplate.js?__contentFilePath=/opt/buildhome/repo/posts/2021/2021-08-10-merge-requests-and-guadec/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-js-content-file-path-posts-2021-2021-08-10-merge-requests-and-guadec-index-mdx" */),
  "component---src-templates-blog-post-template-js-content-file-path-posts-2021-2021-08-20-gsoc-final-review-index-mdx": () => import("./../../../src/templates/blogPostTemplate.js?__contentFilePath=/opt/buildhome/repo/posts/2021/2021-08-20-gsoc-final-review/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-js-content-file-path-posts-2021-2021-08-20-gsoc-final-review-index-mdx" */),
  "component---src-templates-tag-page-template-js": () => import("./../../../src/templates/tagPageTemplate.js" /* webpackChunkName: "component---src-templates-tag-page-template-js" */)
}

